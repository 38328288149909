import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import Layout from 'components/Layout';
import PropTypes from 'prop-types';

function DashboardIndex({ data, location }) {
  const { title } = data.site.siteMetadata;
  return (
    <Layout location={location} title={title}>
      <Helmet>
        <title>Powersave</title>
      </Helmet>
    </Layout>
  );
}
DashboardIndex.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object,
};
export const pageQuery = graphql`
	query {
		site {
			siteMetadata {
				title
			}
		}
	}
`;
export default DashboardIndex;
